export const navMenu = {
  linksMain: [
    {
      key: 'search',
      class: 'nav-menu__links__separator --small-link --bottom',
      search: true,
    },
    { key: 'products', action: 'products' },
    {
      key: 'collections',
      route: { name: 'typology-collections' },
    },
    { key: 'projects', route: { name: 'projects' } },
    { key: 'bespoke', route: { name: 'bespoke' } },

    {
      key: 'news',
      class: 'nav-menu__links__separator --top --small-link',
      route: { name: 'news' },
    },
    { key: 'designers', class: '--small-link', route: { name: 'designers' } },
    { key: 'company', class: '--small-link', route: { name: 'company' } },
    {
      key: 'finishes',
      class: '--small-link',
      route: { name: 'finishes' },
    },
    // { key: 'research', class: '--small-link', route: { name: 'research' } },
    { key: 'catalogues', class: '--small-link', route: { name: 'catalogues' } },
    {
      key: 'contacts',
      class: '--small-link',
      route: { name: 'contacts-addresses' },
    },
    // { key: 'certifications', route: { name: 'certifications' } },
  ],
  linksBack: [
    {
      key: 'back',
      class: 'nav-menu__links__separator --small-link --bottom',
      back: true,
      action: 'main',
    },
  ],
  linksSubMenuProducts: [
    {
      key: 'systems',
      class: 'nav-menu__links__separator --top --small-link',
      route: { name: 'typology-systems' },
    },
  ],
}

export const navTabContact = ($t) => {
  const nuxtApp = useNuxtApp()

  return [
    {
      title: $t('navigation.addresses'),
      route: {
        name: 'contacts-addresses',
      },
    },
    {
      title: $t('navigation.requests'),
      route: {
        name: 'contacts-requests',
      },
    },
    {
      title: $t('navigation.distributors'),
      route: {
        name: 'contacts-distributors-region',
        params: {
          region: nuxtApp.$i18n.locale.value === 'it' ? 'italia' : 'italy',
        },
      },
    },
  ]
}

export const navFooter = () => {
  const nuxtApp = useNuxtApp()

  return {
    linksFirstRow: [
      { key: 'policy', route: { name: 'policy-privacy' } },
      { key: 'cookie', route: { name: 'policy-cookie' } },
      // { key: 'newsletter', href: 'https://www.zavaluce.it' },
    ],
    linksSecondRow: [
      {
        key: 'contacts',
        route: { name: 'contacts-addresses' },
      },
      {
        key: 'distributors',
        route: {
          name: 'contacts-distributors-region',
          params: {
            region: nuxtApp.$i18n.locale.value === 'it' ? 'italia' : 'italy',
          },
        },
      },
      { key: 'support', route: { name: 'contacts-requests' } },
      // { key: 'certifications', route: { name: 'index' } },
      { key: 'catalogues', route: { name: 'catalogues' } },
    ],
    linksSocialRow: [
      {
        key: 'facebook',
        href: 'https://www.facebook.com/zavaluce',
      },
      { key: 'pinterest', href: 'https://www.pinterest.it/zavaluce/' },
      { key: 'instagram', href: 'https://www.instagram.com/zavaluce/' },
    ],
  }
}
